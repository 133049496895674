import React from "react";
import styles from "./styles.module.scss";
import key_logo from "../../assets/img/key.svg";
import rocket_logo from "../../assets/img/rocket.svg";
import shield_logo from "../../assets/img/shield.svg";
export const About = () => {
  return (
    <div className={styles.about_wrapper}>
      <div className={styles.about}>
        <div className={styles.title}>
          <h1>Наши преимущества</h1>
          <p>
            Мы знаем все тонкости рынка криптовалют, и оперативно реагируем на
            каждое изменение для того, чтобы ваш обмен прошел максимально быстро
            и выгодно
          </p>
        </div>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <div>
              <img src={rocket_logo} />
            </div>
            <div className={styles.card_txt}>
              <h1>Скорость</h1>
              <p>
                Мы заботимся о прибыли клиентов на волатильном рынке
                криптовалют, поэтому мы выплачиваем от 5 до 15 минут
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div>
              <img src={key_logo} />
            </div>
            <div className={styles.card_txt}>
              <h1>Надёжность</h1>
              <p>Мы 6 лет на рынке. Более 10 000 довольных клиентов</p>
            </div>
          </div>
          <div className={styles.card}>
            <div>
              <img src={shield_logo} />
            </div>
            <div className={styles.card_txt}>
              <h1>Безопасность</h1>
              <p>
                Для нас важна безопасность клиентов, поэтому мы выбираем
                наиболее безопасные способы обмена
              </p>
            </div>
          </div>
        </div>
        <div className={styles.txt_about}>
          <div className={styles.about_container}>
            <div className={styles.txt_about_item}>
              <h1>6 лет</h1>
              <p>на рынке</p>
            </div>
            <div className={styles.txt_about_item}>
              <h1>от 5 минут</h1>
              <p>время обмена</p>
            </div>
            <div className={styles.txt_about_item}>
              <h1>10000+</h1>
              <p>довольных клиентов</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
