import React from "react";
import { Logo } from "../Logo/Logo";
import styles from "./styles.module.scss";
import bc_logo from "../../assets/img/bestchange.svg";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  const clickHandle = () => {
    navigate("information");
  };
  return (
    <footer className={styles.footer_wrapper}>
      <div className={styles.footer_container}>
        <div className={styles.left_container}>
          <Logo />
          <h4>Сервис обмена электронных валют</h4>
          <div className={styles.bc_logo}>
            <a rel="nofollow" href="https://www.bestchange.ru">
              <img src={bc_logo} />
            </a>
          </div>
        </div>
        <div>
          <div className={styles.right_container}>
            <div className={styles.support_div}>
              <h3>Центр поддержки: </h3>
              <a href="https://t.me/TokenTrade_Support">
                {" "}
                <h3>Telegram Support</h3>
              </a>
            </div>
            <div>
              <h4 onClick={clickHandle} className={styles.rules}>
                Правила сервиса
              </h4>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
