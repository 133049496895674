import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { tickers } from "../../storage";
import styles from "./styles.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
export const ConfirmPage = () => {
  const a = useSelector((e) => e.dataReducer.dealData);
  const location = useLocation();
  console.log(location);
  const firstCrypto = tickers.filter((n) => {
    return n.name.toLowerCase().includes(a.firstCrypto.toLowerCase());
  });
  const secondCrypto = tickers.filter((n) => {
    return n.name.toLowerCase().includes(a.secondCrypto.toLowerCase());
  });

  const navigation = useNavigate();

  const onClickHandle = () => {
    navigation("/deal");
  };

  return (
    <div className={styles.page_wrapper}>
      <div className={styles.page_container}>
        <div className={styles.main_div}>
          <h1 className={styles.title}>Подтверждение сделки</h1>
          <div className={styles.row_container}>
            <div className={styles.row_wrapper}>
              <h3 className={styles.row_title}>Id Сделки: </h3>{" "}
              <h3 className={styles.row_info}>{a.id}</h3>
            </div>
            <div className={styles.row_wrapper}>
              <h3 className={styles.row_title}>Отдаёте: </h3>{" "}
              <h3 className={styles.row_info}>
                {a.firstCryptoValue}{" "}
                <img src={firstCrypto[0].img} className={styles.crypto_img} />{" "}
                {firstCrypto[0].fullName}
              </h3>
            </div>
            <div className={styles.row_wrapper}>
              <h3 className={styles.row_title}>Получаете: </h3>{" "}
              <h3 className={styles.row_info}>
                {a.exchangeValue}{" "}
                <img src={secondCrypto[0].img} className={styles.crypto_img} />{" "}
                {a.secondCrypto}
              </h3>
            </div>
            <div className={styles.row_wrapper}>
              <h3 className={styles.row_title}>Ваш кошелёк: </h3>{" "}
              <h5 className={styles.row_info}>{a.wallet}</h5>
            </div>
            <div className={styles.row_wrapper}>
              <h3 className={styles.row_title}>Ваш Email:</h3>{" "}
              <h3 className={styles.row_info}>{a.email}</h3>
            </div>
          </div>
          <div>
            <img />
            <p>
              Внимательно проверьте данные сделки, если вы не верно указали свой
              кошелёк, средства будут утеряны, без возможности возврата. Если
              все поля указаны верно, нажмите кнопку "Продолжить"
            </p>
          </div>

          <div className={styles.btn_container}>
            <Button className={styles.btn_div} onClick={onClickHandle}>
              Оплатить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
