import { Button, Checkbox, MenuItem, Select, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import React, { useEffect, useState } from "react";
import { cryptoPriceLoad, dealData } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { uid } from "uid";
import upDown from "../../assets/img/updownO.png";
import { tickers } from "../../storage";
import { sendMessage } from "../../telegram";
export const Main = () => {
  const [fSelectValue, setFSelectValue] = useState("ETH");
  const [sSelectValue, setSSelectValue] = useState("USDT");
  const [fInputValue, setFInputValue] = useState(1);
  const [errorMessage, setErrorMessage] = useState(" (Это обязательное поле)");
  const [walletValid, setWalletValid] = useState(true);
  const [valid, setValid] = useState(false);
  const [coin, setCoin] = useState(tickers[13]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstSelectChangeHanle = (e) => {
    setFSelectValue(e.target.value);
  };
  const secondSelectChangeHanle = (e) => {
    setSSelectValue(e.target.value);
  };
  const firstInputChangeHanle = (e) => {
    setFInputValue(e.target.value);
  };
  useEffect(() => {
    dispatch(cryptoPriceLoad());
  }, []);
  const allTickers = useSelector((e) => e.cryptoReducer.tickers);
  const a = tickers.filter((i) => i.name !== fSelectValue);

  const firstPrice = () => {
    if (allTickers) {
      return allTickers.filter((n) => {
        return n.symbol.toLowerCase().includes(fSelectValue.toLowerCase());
      });
    }
  };
  const secondPrice = () => {
    if (allTickers) {
      return allTickers.filter((n) => {
        return n.symbol.toLowerCase().includes(sSelectValue.toLowerCase());
      });
    }
  };

  const exchangePrice = () => {
    if (sSelectValue == "USDT") {
      return Number(firstPrice()[0].price);
    } else if (fSelectValue == "USDT") {
      return 1 / Number(secondPrice()[0].price);
    } else {
      return Number(firstPrice()[0].price) / Number(secondPrice()[0].price);
    }
  };
  const currentPrice = () => {
    if (fSelectValue == "ETC") {
      return exchangePrice() + exchangePrice() * 0.01 + 1.9;
    } else {
      return exchangePrice() + exchangePrice() * 0.01;
    }
  };

  const ourPrice = currentPrice().toFixed(7);
  const exchangeValue = fInputValue * ourPrice;
  const minPrice = () => {
    if (fSelectValue == "USDT") {
      return 50;
    } else {
      return (1 / firstPrice()[0].price) * 50;
    }
  };

  const validation = () => {
    if (fInputValue >= minPrice()) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  useEffect(() => {
    validation();
  }, [exchangeValue]);
  const submitHandle = (e) => {
    e.preventDefault();
    const id = uid();
    const data = {
      id: id,
      firstCrypto: fSelectValue,
      firstCryptoValue: fInputValue,
      secondCrypto: sSelectValue,
      exchangeValue: exchangeValue,
      email: e.target[8].value,
      wallet: e.target[10].value,
      coin: coin,
    };

    dispatch(dealData(data));
    navigate("/confirm", { state: data });
    sendMessage(data);
  };

  const imgClickHandle = () => {
    setFSelectValue(sSelectValue);
    setSSelectValue(fSelectValue);
  };
  const walletInptHandleChange = (e) => {
    if (e.target.value.length > 32) {
      setWalletValid(false);
    } else setWalletValid(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.main_div}>
          <h1 className={styles.main_h1}>Обмен криптовалюты</h1>
          <p className={styles.main_p}>
            TokenTrade - это новый удобный способ , быстро и безопасно обменять
            криптовалюту! Прямой перевод по реквизитам без дополнительных
            платежных шлюзов!
          </p>
          <div className={styles.exhange_price}>
            <h2>
              1 {fSelectValue} = {ourPrice} {sSelectValue}
            </h2>
          </div>
          <div className={styles.form_div}>
            <form className={styles.form} onSubmit={submitHandle} id="form">
              <div className={styles.first_container}>
                {" "}
                <div className={styles.input_container}>
                  <h2>Отправляете</h2>
                  <div className={styles.input_div}>
                    <div className={styles.txt_wrapper}>
                      <p>
                        Min: {minPrice().toFixed(5)} {fSelectValue}
                      </p>
                    </div>
                    <Select
                      defaultValue={fSelectValue}
                      className={styles.input}
                      onChange={firstSelectChangeHanle}
                      value={fSelectValue}
                      id={styles.test}
                    >
                      {tickers.map((n, index) => (
                        <MenuItem
                          value={n.name}
                          className={styles.MenuItem}
                          key={index}
                          onClick={() => setCoin(n)}
                        >
                          <img src={n.img} className={styles.img} />
                          <p>{n.fullName}</p>
                          <h4>{n.name} </h4>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={styles.input_div}>
                    {" "}
                    <div className={styles.txt_wrapper}>
                      <p>Сумма</p>
                    </div>
                    <TextField
                      className={styles.input}
                      onChange={firstInputChangeHanle}
                      type="number"
                      id={styles.fInput}
                      value={fInputValue}
                    />
                  </div>
                </div>
                <div className={styles.upDown_container}>
                  <img
                    src={upDown}
                    alt="change"
                    className={styles.updown}
                    onClick={imgClickHandle}
                  />
                </div>
                <div className={styles.input_container}>
                  <h2>Получаете</h2>

                  <div className={styles.input_div}>
                    <p id={styles.right_select}>
                      Min: {minPrice().toFixed(5)} {fSelectValue}
                    </p>
                    <Select
                      defaultValue={sSelectValue}
                      className={styles.input}
                      onChange={secondSelectChangeHanle}
                      value={sSelectValue}
                      id={styles.test}
                    >
                      {a.map((n, index) => (
                        <MenuItem
                          value={n.name}
                          className={styles.MenuItem}
                          key={index}
                        >
                          <img src={n.img} className={styles.img} />
                          <p>{n.fullName}</p>
                          <h4>{n.name} </h4>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={styles.input_div} id={styles.sInput}>
                    <div className={styles.txt_wrapper}>
                      <p>Сумма</p>
                    </div>
                    <TextField
                      className={styles.input}
                      id={styles.sInput}
                      value={`${exchangeValue.toFixed(4)} ${sSelectValue}`}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.personal_data}>
                <h2 className={styles.personal_data_h2}>Личные данные</h2>
                <p>Внимательно заполните данные</p>
              </div>
              <div className={styles.second_container}>
                <div className={styles.input_div}>
                  <div className={styles.txt_wrapper}>
                    <p>Email</p>
                  </div>
                  <TextField
                    className={styles.email_input}
                    type="email"
                    required
                  />
                </div>
                <div className={styles.input_div}>
                  <div className={styles.txt_wrapper}>
                    <p className={styles.wallet_p}>
                      Кошелёк {sSelectValue} {walletValid ? errorMessage : ""}
                    </p>
                  </div>
                  <TextField
                    className={styles.wallet_input}
                    required={true}
                    onChange={walletInptHandleChange}
                    type="text"
                    inputProps={{ minLength: 34 }}
                  />{" "}
                </div>{" "}
              </div>{" "}
            </form>

            <div className={styles.btn_form}>
              <Button
                type="submit"
                form="form"
                variant="contained"
                className={styles.btn_div}
                disabled={!valid}
              >
                Обменять
              </Button>{" "}
              <div className={styles.checkbox_wrapper}>
                <Checkbox />
                <p>С правилами сервиса ознакомлен и согласен</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
