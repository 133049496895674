import React from "react";

import styles from "./styles.module.scss";
import { Main } from "../../Components/Main/Main";
import { About } from "../../Components/About/About";

export const MainPage = () => {
  return (
    <main className={styles.main__wrapper}>
      <Main />
      <About />
    </main>
  );
};
