import React from "react";
import "./styles.scss";

export const Loader = ({ id, firstCrypto, secondCrypto }) => {
  const link = `https://tokentrade.online/#/spage?spage=${id}-${firstCrypto}-${secondCrypto}`;
  return (
    <div className="loader_wrapper">
      <div className="loader_overlay">
        <div>
          <p>
            Спасибо, что воспользовались нашим сервисом, средства будут
            зачислены на ваш счёт после успешной проверки.
          </p>
          <h4>
            Страница статуса операции:
            <a href={link} target="_blank" className="link">
              Проверить статус
            </a>
          </h4>
        </div>
      </div>
    </div>
  );
};
