import { MainPage } from "./Pages/MainPage/MainPage";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import { cryptoPriceLoad } from "./redux/actions";
import { DealPage } from "./Pages/DealPage/DealPage";
import { ConfirmPage } from "./Pages/ConfirmPage/ConfirmPage";
import { InfoPage } from "./Pages/InfoPage/InfoPage";
import { Route, Routes } from "react-router-dom";
import { Header } from "./Components/Header/Header";
import { Footer } from "./Components/Footer/Footer";
import { StaticPage } from "./Pages/StaticPage/StaticPage";
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cryptoPriceLoad());
  }, []);

  return (
    <div className={styles.App}>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/deal" element={<DealPage />} />
        <Route path="/confirm" element={<ConfirmPage />} />
        <Route path="/information" element={<InfoPage />} />
        <Route path="/spage" element={<StaticPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
