import React from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./styles.module.scss";
import Countdown from "react-countdown";
import { tickers } from "../../storage";
import { CircularProgress } from "@mui/material";
import { Loader } from "../../Components/Loader/Loader";

export const StaticPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = searchParams.get("spage") || "";

  const array = params.split("-");
  console.log(array);
  const firstCrypto = tickers.filter((n) => {
    return n.name.toLowerCase().includes(array[1].toLowerCase());
  });
  const secondCrypto = tickers.filter((n) => {
    return n.name.toLowerCase().includes(array[2].toLowerCase());
  });
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return "";
    } else {
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };
  return (
    <div className={styles.page_wrapper}>
      <div className={styles.page_container}>
        <div className={styles.main_div}>
          <h1 className={styles.title}>Оплата</h1>
          <div className={styles.row_container}>
            <div className={styles.row_wrapper}>
              <h3 className={styles.row_title}>Id Сделки: </h3>{" "}
              <h3 className={styles.row_info}>{array[0]}</h3>
            </div>
            <div className={styles.row_wrapper}>
              <div className={styles.row_wrapper}>
                <h3 className={styles.row_title}>Отдаёте: </h3>{" "}
                <h3 className={styles.row_info}>
                  <img src={firstCrypto[0].img} className={styles.crypto_img} />{" "}
                  {firstCrypto[0].fullName}
                </h3>
              </div>

              <div className={styles.row_wrapper}>
                <h3 className={styles.row_title}> Получаете: </h3>{" "}
                <h3 className={styles.row_info}>
                  {" "}
                  <img
                    src={secondCrypto[0].img}
                    className={styles.crypto_img}
                  />{" "}
                  {array[2]}
                </h3>
              </div>
            </div>

            <div className={styles.status_div}></div>
          </div>{" "}
          <span className={styles.span}>
            Статус: Ожидание оплаты{" "}
            {<CircularProgress style={{ color: "#ff9900" }} />}{" "}
          </span>
          <div className={styles.final_step}>
            <h3 className={styles.description}>
              Криптовалюта будет автоматически переведена после успешной
              проверки.Среднее время ожидания:
            </h3>
            <h2 className={styles.h2_wallet}>
              <Countdown date={Date.now() + 900000} renderer={renderer} />
            </h2>
          </div>
          <p className={styles.attention}>
            Если у вас возникли проблемы, обратитесь в техническую поддержку:{" "}
            <h3>support@tokentrade.online</h3>
          </p>
        </div>
      </div>
    </div>
  );
};
