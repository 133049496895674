export const tickers = [
  {
    name: "USDT",
    img: require("./assets/img/logos/usdt.png"),
    wallet: "0x4D4Dd067cBb892280e56240D5B36596285F6480A",
    fullName: "Tether ERC-20",
  },
  {
    name: "USDT",
    img: require("./assets/img/logos/usdt.png"),
    wallet: "TAGTrx8KLxnsNxt3icTARNkibn554ixcrj",
    fullName: "Tether TRC-20",
  },
  {
    name: "ETH",
    img: require("./assets/img/logos/eth.png"),
    wallet: "0x4D4Dd067cBb892280e56240D5B36596285F6480A",
    fullName: "Ethereum",
  },
  {
    name: "BTC",
    img: require("./assets/img/logos/btc.png"),
    wallet: "bc1q3t60clk2jlh6lg5mxqcmsvfnptdk2dhhn78uly",
    fullName: "Bitcoin",
  },
  {
    name: "LTC",
    img: require("./assets/img/logos/ltc.png"),
    wallet: "LKnciy96r6fNMXKFPkwcjxZPT9BJqza8EY",
    fullName: "Litecoin",
  },
  {
    name: "DOGE",
    img: require("./assets/img/logos/doge.png"),
    wallet: "DDMxFCKdz4Txuh54MWiWCFyCYpkneWrKUn",
    fullName: "Dogecoin",
  },
  {
    name: "ADA",
    img: require("./assets/img/logos/ada.png"),
    wallet:
      "addr1q8gfpf7aavl4ew4nu5td3ygvav7pxz3qy5053tcj637gl27sjznam6eltjat8egkmzgse6euzv9zqfglfzh394ru374s2f4l26",
    fullName: "Cardano",
  },
  {
    name: "XRP",
    img: require("./assets/img/logos/xrp.png"),
    wallet: "rLmuruU2i9XqGWyoiQTKmYWmeTBytxmrK1",
    fullName: "Ripple",
  },
  {
    name: "SOL",
    img: require("./assets/img/logos/sol.png"),
    wallet: "46LYVUWngRa5G9D1gyZbMeF99S55S7oS755oXJkXQBpG",
    fullName: "Solana",
  },
  {
    name: "DOT",
    img: require("./assets/img/logos/dot.png"),
    wallet: "12EwP7yqPGXSHG6uwY92By6yPp7guAoQbbErEKJdLcLYcVTA",
    fullName: "Polkadot",
  },
  {
    name: "AVAX",
    img: require("./assets/img/logos/avax.png"),
    wallet: "0x4D4Dd067cBb892280e56240D5B36596285F6480A",
    fullName: "Avalanche",
  },
  {
    name: "TRX",
    img: require("./assets/img/logos/trx.png"),
    wallet: "TDiGg2BtcnoKV87CvZd91AzLZaPRv32PEC",
    fullName: "TRON",
  },
  {
    name: "MATIC",
    img: require("./assets/img/logos/matic.png"),
    wallet: "0x4D4Dd067cBb892280e56240D5B36596285F6480A",
    fullName: "Polygon",
  },
  {
    name: "NEAR",
    img: require("./assets/img/logos/near.png"),
    wallet:
      "0x4D4Dd067cBb892280e56240D5B36596285F6480A",
    fullName: "NEAR Protocol",
  },
  {
    name: "BCH",
    img: require("./assets/img/logos/bch.png"),
    wallet: "qpzazdznw9vmdu76xa5gu3asyttqnzl2js62z7pvzu",
    fullName: "Bitcoin Cash",
  },
  {
    name: "XLM",
    img: require("./assets/img/logos/xlm.png"),
    wallet: "GBB6IPUKJZHCHV2TNSBVMDBIBRZBCDE5YM4PAENWSYEI2NURLXTQZFKU",
    fullName: "Stellar",
  },
  {
    name: "ATOM",
    img: require("./assets/img/logos/atom.png"),
    wallet: "cosmos1lgh74pgu2vk4ran0gtwnphydxjy7rzcyllnylp",
    fullName: "Cosmos",
  },
  {
    name: "ALGO",
    img: require("./assets/img/logos/algo.png"),
    wallet: "GS4VEKP4YOVWAIJ4ZFHMZPFPTJGK6ZOYWZEYRYMWIYUCYDTHWVVCYMYIDQ",
    fullName: "Algorand",
  },
  {
    name: "ETC",
    img: require("./assets/img/logos/etc.png"),
    wallet: "0x9ff998e3ab7968259950bB05E4d148D3E8f7F606",
    fullName: "Ethereum Classic",
  },
  {
    name: "EGLD",
    img: require("./assets/img/logos/egld.png"),
    wallet: "erd17dc74d29fe4vqt2c8gz0v5zvcm2dk8jafpqskknns6dlf88wyl2sq9nrg8",
    fullName: "Elrond",
  },
  {
    name: "FIL",
    img: require("./assets/img/logos/fil.png"),
    wallet: "f1y4oghjd4ayvmx75eja4dzh2qebv42b6yata77mq",
    fullName: "Filecoin",
  },
  {
    name: "XTZ",
    img: require("./assets/img/logos/xtz.png"),
    wallet: "tz1iKpTHM69KTw1PiQTaufBbZsUKNpYFjRN8",
    fullName: "Tezos",
  },
  {
    name: "ZEC",
    img: require("./assets/img/logos/zec.png"),
    wallet: "t1PQLVER5mxxEJfgQd6uzcpk3eje9eTfsCc",
    fullName: "Zcash",
  },
  {
    name: "THETA",
    img: require("./assets/img/logos/theta.png"),
    wallet: "0xf259D8FaA72457F56739cBf5AD37131512788159",
    fullName: "Theta Network",
  },
  {
    name: "FTM",
    img: require("./assets/img/logos/ftm.png"),
    wallet: "0x4D4Dd067cBb892280e56240D5B36596285F6480A",
    fullName: "Fantom",
  },
  
  {
    name: "DASH",
    img: require("./assets/img/logos/dash.png"),
    wallet: "XmrJqvqrmcUtDiB5AxZ7Q6gcC9ScGNc9oG",
    fullName: "Dash",
  },
].sort(function (a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
});
