import { TICKERS_LOAD } from "./types";

const initialState = {
  tickers: [{ symbol: "ETHUSDT", price: "1504.18000000" }],
};

export const cryptoReducer = (state = initialState, action) => {
  switch (action.type) {
    case TICKERS_LOAD:
      return {
        ...state,
        tickers: action.data,
      };
    default: {
      return state;
    }
  }
};
