import React from "react";
import styles from "./styles.module.scss";

export const InfoPage = () => {
  return (
    <div>
      <div className={styles.info_header}></div>
      <div>
        <h1>1. Стороны договора</h1>
        <p>
          1.1. Соглашение заключается между онлайн-сервисом по обмену титульных
          знаков, в дальнейшем Исполнитель, и лицом, использовавшим услуги
          Исполнителя, в дальнейшем - Заказчик.
        </p>{" "}
        <h1>2. Перечень терминов</h1>
        <p>
          2.1. Обмен титульных знаков - предоставляемая Исполнителем
          автоматизированная услуга интернет обслуживания, определенная и
          обоснованная действующими правилами. 2.2. Титульный знак - Условная
          единица той или иной платежной системы, эквивалентная единице,
          используемой в расчетах электронных систем и обозначающая совокупность
          прав, соответствующих договору системы электронных платежей и её
          Заказчика. 2.3. Заявка - информация, которую Заказчик передал
          Исполнителю для использования средств Исполнителя в электронном
          формате, свидетельствующая о том, что Заказчик принимает правила и
          условия пользования услугами Исполнителя в рамках данной заявки.
        </p>
        <h1>3. Условия договора</h1>
        <p>
          3.1. Все описанные правила считаются организованными за счет условий
          общественной оферты, образующейся во время подачи Заказчиком заявки и
          являющейся одной из главных составляющей настоящего договора.
          Общественной офертой являются отображаемая Исполнителем информация об
          условиях подачи заявки. Первостепенными составляющими общественной
          оферты являются действия, сделанные в завершении подачи заявки
          Заказчиком и свидетельствующие о его точных намерениях совершить
          сделку на условиях предложенных Исполнителем перед завершением данной
          сделки. Время, дата, и прочие параметры заявки создаются Исполнителем
          автоматически в момент завершения формирования данной заявки.
          Предложение должно быть принято Заказчиком в течение 24 часов от
          завершения формирования заявки. Договор по обслуживанию вступает в
          силу с момента поступления титульных знаков в полном размере,
          указанном в заявке, от Заказчика на реквизиты Исполнителя. Операции с
          титульными знаками учитываются согласно правилам, регламенту и формату
          электронных платежных систем. Договор действителен в течение срока ,
          который устанавливается с момента подачи заявки до расторжения по
          инициативе одной из сторон.
        </p>
        <h1>4. Предмет договора</h1>
        <p>
          5. Дополнительно 5.1. В случае, когда на счет Исполнителя поступает
          сумма, отличающаяся от указанной Заказчиком в заявке, Исполнитель
          имеет право совершить перерасчет по фактически поступившему количеству
          титульных знаков, после чего выплата производится по факту поступления
          средств на счет Исполнителя. 5.2. В случае, когда титульные знаки не
          пересылаются Исполнителем на указанные в заявке Заказчиком реквизиты
          по истечении 24 часов, Заказчик имеет право потребовать расторжение
          соглашения и аннулирование своей заявки, тем самым совершая возврат
          титульных знаков на свой счет в полном объеме. Заявка на расторжение
          соглашения и возврати титульных знаков принимается и исполняется
          Исполнителем только в случае, если средства не были переведены на
          указанные в заявке реквизиты Заказчика. В случае аннулирования
          договора, возврат титульных знаков производится в течение 24 часов с
          момента получения требования о расторжении договора. В случае, когда
          задержки при возврате возникли не по вине Исполнителя, он не несет за
          них ответственности. 5.3. В случае, когда титульные знаки не поступают
          от Заказчика на счет Исполнителя в течение указанного срока, с момента
          подачи заявки Заказчиком, соглашение между сторонами расторгается
          Исполнителем с одной стороны, так как договор не вступает в действие.
          Заказчик может об этом не уведомляться. Если титульные знаки поступают
          на реквизиты Исполнителя после указанного срока, то такие средства
          переводятся обратно на счет Заказчика за вычетом всех комиссионных
          расходов. 5.4. В случае, когда происходит задержка перевода средств на
          реквизиты, указанные Заказчиком в заявке, по вине расчетной системы,
          Исполнитель не несет ответственности за потенциальный и фактический
          ущерб, возникающий в результате задержки. В этом случае Заказчик
          соглашается с тем, что все претензии должны предъявляться к расчетной
          системе, а Исполнитель оказывает содействие Заказчику по мере своих
          возможностей в рамках закона. 5.5. В случае обнаружения Исполнителем
          подделки коммуникационных потоков или оказания негативного
          воздействия, с целью ухудшить работу Исполнителя, а именно его
          программного обеспечения, исполнение заявки приостанавливается, а
          полученные средства подвергаются перерасчету в соответствии с
          действующим договором. Если Заказчик не согласен с перерасчетом, он
          имеет право расторгнуть договор и титульные знаки будут отправлены на
          указанные Заказчиком реквизиты . 5.6 В случае использования услуг
          Исполнителя, Заказчик полностью соглашается с тем, что Исполнитель
          несет ограниченную рамкам настоящих правил ответственность и не дает
          дополнительных гарантий Заказчику, а также не несет перед ним
          дополнительной ответственности. Соответственно Заказчик не несет
          дополнительной ответственности перед Исполнителем. 5.7 Заказчик
          обязуется не нарушать нормы действующего законодательства, а также не
          подделывать коммуникационные потоки и не препятствовать для нормальной
          работе программного обеспечения Исполнителя. 5.8 Исполнитель не несет
          ответственности за ущерб от ошибочного перевода электронной валюты в
          том случае, если Заказчик указал при подаче заявки неверные реквизиты.
        </p>
        <h1>6. Гарантийный период</h1>
        <p>
          6.1. В течение 24 часов с момента завершения обмена титульных знаков
          Исполнитель дает гарантию на предоставляемые услуги при условии, если
          не оговорены иные сроки.
        </p>
        <h1>7. Обстоятельства, не зависящие от воли сторон</h1>
        <p>
          7.1. В случае, когда в процессе исполнения заявки Заказчика возникают
          непредвиденные обстоятельства, ведущие к невыполнению Исполнителем
          условий договора, сроки выполнения заявки переносятся на
          соответствующий срок длительности непредвиденных обстоятельств. За
          смещение сроков исполнения обязательства Исполнитель ответственности
          не несет.
        </p>
        <h1>8. Форма соглашения</h1>
        <p>
          8.1. Данное соглашение обе стороны, в лице Исполнителя и Заказчика,
          принимают как равноценный по юридической силе договору, обозначенному
          в письменной форме.
        </p>
        <h1>9. Операции с картами Великобритании, Германии и США</h1>
        <p>
          9.1. Для владельцев карт юрисдикции стран Великобритании, Германии и
          США сроки перевода титульных знаков продляются на неопределенный срок,
          соответствующий полной проверке данных владельца карты. Денежные
          средства в течение всего срока проверки не подвергаются никаким
          операциям и в полном размере находятся на счете Исполнителя.
        </p>
        <h1>10. Претензии</h1>
        <p>
          10.1. Претензии по действующему соглашению принимаются Исполнителем в
          формате электронного письма, в котором Заказчик указывает суть
          претензии. Данное письмо отправляется на указанные на сайте контактные
          данные Исполнителя.
        </p>
        <h1> 11. Проведение обменных операций</h1>
        <p>
          11.1. Запрещается использование услуг Исполнителя для незаконных
          переводов и мошеннических действий. При заключении данного договора,
          Заказчик обязуется выполнять эти требования и в случае выявления
          совершения мошеннических действий нести уголовную ответственность,
          установленную законодательством. 11.2. В случае невозможности
          выполнения обмена автоматически, по не зависящим от Исполнителя
          обстоятельствам, таким как блокировка оплаты платежной системой,
          отсутствие интернет- соединения, нехватка средств на счетах Заказчика,
          или же ошибочные данные Заказчика, средства поступают на счет в
          течение последующих 24 часов или же возвращаются на реквизиты
          Заказчика за вычетом комиссионных расходов. 11.3. В результате
          доказанного судебными органами мошенничества по первому требованию
          правоохранительных органов, администрации расчетных систем, а также
          жертв неправомерных действий Исполнитель вправе передавать информацию
          о переводе электронной валюты. 11.4. Заказчик обязуется предоставлять
          все документы, удостоверяющие его личность, в случае подозрения о
          мошенничестве и/или отмывании денег. 11.5. Заказчик обязуется не
          вмешиваться в работу Исполнителя и не наносить урон его программной и
          аппаратной части, а также Заказчик обязуется передавать точные
          сведения для обеспечения выполнения Исполнителем всех условий
          договора.
        </p>
      </div>
    </div>
  );
};
