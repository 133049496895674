import { Button, Checkbox } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Loader } from "../../Components/Loader/Loader";

import { tickers } from "../../storage";
import styles from "./styles.module.scss";

export const DealPage = () => {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const [message, setMessage] = useState("");

  const a = useSelector((e) => e.dataReducer.dealData);

  const firstCrypto = tickers.filter((n) => {
    return n.name.toLowerCase().includes(a.firstCrypto.toLowerCase());
  });
  const secondCrypto = tickers.filter((n) => {
    return n.name.toLowerCase().includes(a.secondCrypto.toLowerCase());
  });
  const chackBoxHandle = (e) => {
    setChecked(e.target.checked);
  };
  const btnClickHandle = () => {
    setLoading(true);
  };
  const toClipboard = () => {
    navigator.clipboard.writeText(firstCrypto[0].wallet);
    setMessage("Ардес скопирован в буфер обмена");
  };
  return (
    <div className={styles.page_wrapper}>
      <div className={styles.page_container}>
        <div className={styles.main_div}>
          <h1 className={styles.title}>Оплата</h1>
          <div className={styles.row_container}>
            <div className={styles.row_wrapper}>
              <h3 className={styles.row_title}>Id Сделки: </h3>{" "}
              <h3 className={styles.row_info}>{a.id}</h3>
            </div>
            <div className={styles.row_wrapper}>
              <div className={styles.row_wrapper}>
                <h3 className={styles.row_title}>Отдаёте: </h3>{" "}
                <h3 className={styles.row_info}>
                  {a.firstCryptoValue}{" "}
                  <img src={firstCrypto[0].img} className={styles.crypto_img} />{" "}
                  {firstCrypto[0].fullName}
                </h3>
              </div>

              <div className={styles.row_wrapper}>
                <h3 className={styles.row_title}> Получаете: </h3>{" "}
                <h3 className={styles.row_info}>
                  {a.exchangeValue.toFixed(4)}{" "}
                  <img
                    src={secondCrypto[0].img}
                    className={styles.crypto_img}
                  />{" "}
                  {a.secondCrypto}
                </h3>
              </div>
            </div>

            <div className={styles.status_div}></div>
          </div>{" "}
          <div className={styles.final_step}>
            <h3 className={styles.description}>
              Переведите средства в размере {a.firstCryptoValue}{" "}
              <img src={firstCrypto[0].img} className={styles.crypto_img} />{" "}
              {firstCrypto[0].name} на кошелёк
            </h3>
            <h2 className={styles.h2_wallet}>{a.coin.wallet}</h2>
            <Button onClick={toClipboard} className={styles.copy_ptn}>
              Скопировать адресс
            </Button>
            <p className={styles.adress}>{message}</p>
          </div>
          <p className={styles.attention}>
            Внимательно относитесь к копированию адреса, чтобы не пропустить
            никакой символ (иначе средства бутуд безвозвратно утеряны)
          </p>
          <div className={styles.btn_form}>
            <Button
              disabled={!checked}
              className={checked ? styles.btn_div : styles.btn_disables}
              onClick={btnClickHandle}
            >
              Оплачено
            </Button>
            <div className={styles.checkbox_wrapper}>
              <Checkbox onChange={chackBoxHandle} />
              <p>Средства переведены в полном размере.</p>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <Loader
          firstCrypto={firstCrypto[0].name}
          secondCrypto={secondCrypto[0].name}
          id={a.id}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
