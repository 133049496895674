import React from "react";
import styles from "./styles.module.scss";
import ex_logo from "../../assets/img/t-logo.png";
export const Logo = () => {
  return (
    <div className={styles.logo_div}>
      <img src={ex_logo} />
    </div>
  );
};
