import { DEAL_DATA } from "./types";

const innitialState = {};

export const dataReducer = (state = innitialState, action) => {
  switch (action.type) {
    case DEAL_DATA:
      return { ...state, dealData: { ...state.dealData, ...action.payload } };
    default:
      return state;
  }
};
