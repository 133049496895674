import { TICKERS_LOAD,DEAL_DATA } from "./types";

const ticker = `"BTCUSDT","ETHUSDT","LTCUSDT","XRPUSDT","TRXUSDT","SOLUSDT","DOGEUSDT","DOTUSDT","XLMUSDT","ADAUSDT","BCHUSDT","MATICUSDT","ETCUSDT","NEARUSDT","ATOMUSDT","FTMUSDT","DASHUSDT","XTZUSDT","THETAUSDT","ZECUSDT","EGLDUSDT","ALGOUSDT","FILUSDT","AVAXUSDT"`;
// const ticker = `"BTCUSDT","ETHUSDT","LTCUSDT","XRPUSDT","BNBUSDT","TRXUSDT","SOLUSDT","DOGEUSDT","DOTUSDT","XLMUSDT","ADAUSDT","BCHUSDT","MATICUSDT","ETCUSDT","NEARUSDT","ATOMUSDT","FTMUSDT","DASHUSDT","XTZUSDT","THETAUSDT","ZECUSDT","EGLDUSDT","ALGOUSDT","FILUSDT","AVAXUSDT"`;

export const cryptoPriceLoad = () => {
  return async (dispatch) => {
    const res = await fetch(
      `https://api.binance.com/api/v3/ticker/price?symbols=[${ticker}]`
    );
    const crypto = await res.json();
    dispatch({ type: TICKERS_LOAD, data: crypto });
  };
};

export const dealData = (data) => {
  return {
    type: DEAL_DATA,
    payload: data,
  };
};
