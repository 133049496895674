import React from "react";
import { Logo } from "../Logo/Logo";
import styles from "./styles.module.scss";

export const Header = () => {
  return (
    <header className={styles.header_wrapper}>
      <div className={styles.header_container}>
        <Logo />
        <div></div>
      </div>
    </header>
  );
};
